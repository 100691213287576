import dayjs, { type Dayjs } from 'dayjs'

import { FvDate, type FvDateProps } from '@fv/client-components'

import { AdminTooltip } from './AdminTooltip'

type Props = FvDateProps
export const AdminDate = (props: Props) => {
  const { val, utc = true, format = 'MM/DD/YYYY HH:mm A' } = props
  return (
    <AdminTooltip label={<TimeConversionChart val={val} utc={utc} />}>
      <span className="underline decoration-primary">
        <FvDate format={format} {...props} />
      </span>
    </AdminTooltip>
  )
}

const isDst = (date: Date) => {
  const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset()
  const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset()
  return Math.max(jan, jul) !== date.getTimezoneOffset()
}
type ChartProps = {
  val: Date | string | number | Dayjs
  utc: boolean
}
const TimeConversionChart = ({ val, utc }: ChartProps) => {
  const dateVal = utc ? dayjs.utc(val) : dayjs(val)

  return (
    <div className="text-xs w-52">
      <div className="font-bold -mt-1 pb-2 -mx-3 px-3 border-b border-b-primary">
        Time conversions
      </div>
      <div className="pt-2">
        <ConversionRow
          label="UTC"
          value={dateVal.utc().format('YYYY-MM-DD HH:mm A')}
        />
        <ConversionRow
          label="Your time"
          value={dateVal.local().format('YYYY-MM-DD HH:mm A')}
        />
        <ConversionRow
          label={isDst(dateVal.toDate()) ? 'CDT' : 'CST'}
          value={dateVal.tz('America/Chicago').format('YYYY-MM-DD HH:mm A')}
        />
      </div>
    </div>
  )
}
type RowProps = {
  label: string
  value: string
}
const ConversionRow = ({ label, value }: RowProps) => {
  return (
    <div className="flex justify-between border-b -mx-3 px-3 py-1 last:border-b-0">
      <div>{label}:</div>
      <div className="text-right">{value}</div>
    </div>
  )
}
