export const charges = [
  { code: '', name: 'additional handling' },
  { code: '', name: 'adult signature required' },
  { code: '', name: 'airport delivery' },
  { code: '', name: 'airport pickup' },
  { code: 'MNC', name: 'arrival notice' },
  { code: 'APT', name: 'arrival schedule' },
  { code: 'BSR', name: 'base rate' },
  { code: '', name: 'basic' },
  { code: '', name: 'basic by appointment' },
  { code: '', name: 'blind shipment' },
  { code: 'BPF', name: 'border processing fee' },
  { code: 'CCS', name: 'california compliance surcharge' },
  { code: '', name: 'camp site delivery' },
  { code: '', name: 'camp site pickup' },
  { code: 'CEP', name: 'certified pickup' },
  { code: 'COD', name: 'collect on delivery' },
  { code: 'CMB', name: 'combined accessorials' },
  { code: 'CCS', name: 'compliance surcharge' },
  { code: 'CSD', name: 'construction site delivery' },
  { code: 'CSP', name: 'construction site pickup' },
  { code: 'BRF', name: 'customs broker' },
  { code: '', name: 'deficit charge' },
  { code: '', name: 'delivery confirmation' },
  { code: '', name: 'detention fee' },
  { code: 'DSC', name: 'discount' },
  { code: '', name: 'driver-assist' },
  { code: '', name: 'driver layover' },
  { code: '', name: 'equipment drop' },
  { code: '', name: 'equipment ordered - not used' },
  { code: 'EXP', name: 'expedited' },
  { code: '', name: 'fair delivery' },
  { code: '', name: 'fair pickup' },
  { code: 'FAD', name: 'farm delivery' },
  { code: 'FAP', name: 'farm pickup' },
  { code: 'FER', name: 'ferry surcharge' },
  { code: 'FGP', name: 'food grade protection' },
  { code: 'FVMU', name: 'freightview markup' },
  { code: 'FSC', name: 'fuel' },
  { code: 'FSC', name: 'fuel surcharge' },
  { code: '', name: 'golf delivery' },
  { code: '', name: 'golf pickup' },
  { code: '', name: 'grocery delivery' },
  { code: '', name: 'grocery pickup' },
  { code: 'GRS', name: 'guaranteed' },
  { code: 'HAZ', name: 'hazardous' },
  { code: 'HCD', name: 'high cost area surcharge' },
  { code: '', name: 'hold at location' },
  { code: 'HOL', name: 'holiday delivery' },
  { code: 'HOP', name: 'holiday pickup' },
  { code: '', name: 'home delivery premium' },
  { code: '', name: 'hospital delivery' },
  { code: '', name: 'hospital pickup' },
  { code: '', name: 'hotel delivery' },
  { code: '', name: 'hotel pickup' },
  { code: '', name: 'indian reserve delivery' },
  { code: '', name: 'indian reserve pickup' },
  { code: '', name: 'indirect signature required' },
  { code: 'IDL', name: 'inside delivery' },
  { code: 'IPU', name: 'inside pickup' },
  { code: 'INS', name: 'insurance' },
  { code: 'EVS', name: 'insured value' },
  { code: 'INT', name: 'international' },
  { code: 'LGD', name: 'liftgate delivery' },
  { code: 'LGP', name: 'liftgate pickup' },
  { code: 'LAD', name: 'limited access delivery' },
  { code: 'LAP', name: 'limited access pickup' },
  { code: 'LFR', name: 'linear foot rule' },
  { code: 'LNH', name: 'linehaul' },
  { code: '', name: 'loading - driver' },
  { code: '', name: 'loading - lumper' },
  { code: '', name: 'mall delivery' },
  { code: '', name: 'mall pickup' },
  { code: 'GVD', name: 'military delivery' },
  { code: 'GVP', name: 'military pickup' },
  { code: '', name: 'mine delivery' },
  { code: '', name: 'mine pickup' },
  { code: 'MIC', name: 'minimum charge' },
  { code: '', name: 'multi-stop' },
  { code: '', name: 'notify' },
  { code: 'ON_CALL_PICKUP', name: 'on call pickup' },
  { code: '', name: 'off-hours delivery' },
  { code: '', name: 'off-hours pickup' },
  { code: 'OTR', name: 'other' },
  { code: 'OVR', name: 'over dimension' },
  { code: 'PLT', name: 'pallet' },
  { code: 'PRD', name: 'pier delivery' },
  { code: 'PRP', name: 'pier pickup' },
  { code: '', name: 'place of worship delivery' },
  { code: '', name: 'place of worship pickup' },
  { code: '', name: 'premium' },
  { code: 'PSD', name: 'prison delivery' },
  { code: 'PSP', name: 'prison pickup' },
  { code: 'PFF', name: 'protect from freezing' },
  { code: '', name: 'reconsignment' },
  { code: '', name: 'redelivery' },
  { code: '', name: 'residential' },
  { code: 'RED', name: 'residential delivery' },
  { code: 'REP', name: 'residential pickup' },
  { code: '', name: 'restaurant delivery' },
  { code: '', name: 'restaurant pickup' },
  { code: '', name: 'retirement delivery' },
  { code: '', name: 'retirement pickup' },
  { code: 'SAD', name: 'saturday delivery' },
  { code: 'SAP', name: 'saturday pickup' },
  { code: '', name: 'schedule' },
  { code: '', name: 'schedule pickup' },
  { code: 'SCD', name: 'school delivery' },
  { code: 'SCP', name: 'school pickup' },
  { code: 'IAC', name: 'security inspection' },
  { code: '', name: 'self storage delivery' },
  { code: '', name: 'self storage pickup' },
  { code: '', name: 'signature required' },
  { code: 'SSF', name: 'single shipment' },
  { code: 'SEG', name: 'sort and segregate' },
  { code: '', name: 'stadium delivery' },
  { code: '', name: 'stadium pickup' },
  { code: '', name: 'standard' },
  { code: '', name: 'storage' },
  { code: 'SUF', name: 'sufferance warehouse delivery' },
  { code: 'SUD', name: 'sunday delivery' },
  { code: 'SUP', name: 'sunday pickup' },
  { code: '', name: 'tarp' },
  { code: 'TMD', name: 'terminal dropoff' },
  { code: 'TMP', name: 'terminal pickup' },
  { code: 'EBD', name: 'tradeshow delivery' },
  { code: 'EBP', name: 'tradeshow pickup' },
  { code: '', name: 'team drivers' },
  { code: '', name: 'truckload permit' },
  { code: '', name: 'twic card required' },
  { code: '', name: 'unloading - driver' },
  { code: '', name: 'unloading - lumper' },
  { code: '', name: 'utility delivery' },
  { code: '', name: 'utility pickup' },
  { code: '', name: 'weekend/holiday' },
  { code: '', name: 'white glove service' },
  { code: '', name: 'temp control' },
  { code: '', name: 'e-track' },
  { code: '', name: 'load bars' },
  { code: '', name: 'chains' },
  { code: '', name: 'straps' },
  { code: '', name: 'tanker endorsement' },
  { code: '', name: 'pipe stakes' },
  { code: '', name: 'stop-off' },
] as const satisfies Readonly<Array<{ code: string; name: string }>>

export type ChargeCode = (typeof charges)[number]['code']
export type ChargeName = (typeof charges)[number]['name']

export const chargeNames = charges.map(c => c.name)
export const chargeCodes = charges.map(c => c.code).filter(c => c !== '')

export const chargeNameFromCode = (code: ChargeCode): ChargeName => {
  return charges.find(v => v.code === code)?.name
}
