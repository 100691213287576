import { vPicklist } from '../validation'
import { Mode, Workflow } from './common'

export type EquipmentType =
  | 'dry-van-53'
  | 'dry-van-48'
  | 'flatbed-53'
  | 'flatbed-48'
  | 'refrigerated-48'
  | 'refrigerated-53'
  | 'step-deck'
  | 'flatbed-lowboy'
  | 'air-freight'
  | 'box-truck-16'
  | 'box-truck-24'
  | 'bulk-aluminum'
  | 'bulk-auger-semi'
  | 'bulk-auger-straight-truck'
  | 'bulk-belt-trailer'
  | 'bulk-convertible-hopper'
  | 'bulk-end-dump'
  | 'bulk-hopper'
  | 'bulk-insulated'
  | 'bulk-lined'
  | 'bulk-one-tank'
  | 'bulk-pneumatic'
  | 'bulk-stainless'
  | 'bulk-three-hole'
  | 'bulk-four-hole'
  | 'bulk-two-hole'
  | 'bulk-walking-floor'
  | 'bulk-bag-combo'
  | 'conestoga'
  | 'consolidated'
  | 'crazy8-flatbed'
  | 'curtain-side-van'
  | 'drayage-20'
  | 'drayage-40'
  | 'drayage-45'
  | 'drayage-48'
  | 'drayage-53'
  | 'flatbed-48-double-drop'
  | 'flatbed-48-step'
  | 'flatbed-53-step'
  | 'flatbed-b-train'
  | 'flatbed-heavy-haul'
  | 'flatbed-removable-gooseneck(rgn)'
  | 'flatbed-self-unloader'
  | 'flatbed-with-piggyback'
  | 'flatbed-tri-axle'
  | 'hotshot'
  | 'intermodal-20'
  | 'intermodal-40'
  | 'intermodal-45'
  | 'intermodal-48'
  | 'intermodal-53'
  | 'logistics-van-53'
  | 'ltl'
  | 'padded-van'
  | 'parcel'
  | 'partial-dry-van'
  | 'partial-flatbed'
  | 'partial-refrigerated'
  | 'power-only'
  | 'pup'
  | 'refrigerated-ltl'
  | 'sprinter-van'
  | 'straight-truck-20'
  | 'straight-truck-26'
  | 'tanker'
  | 'volume-ltl'

export const equipmentAccessorials = [
  'chains',
  'e-track',
  'food-grade',
  'load-bars',
  'tarps',
  'temp',
  'straps',
  'protect-from-freezing',
  'liftgate',
  'team-driver',
  'tanker-endorsement',
  'pipe-stakes',
] as const
export type EquipmentAccessorialType = (typeof equipmentAccessorials)[number]

const defaultAccessorials: EquipmentAccessorialType[] = ['team-driver']
const dryVanAccessorials: EquipmentAccessorialType[] = [
  'load-bars',
  'straps',
  'food-grade',
  'e-track',
  'team-driver',
  'liftgate',
  'tanker-endorsement',
]
const flatbedAccessorials: EquipmentAccessorialType[] = [
  'tarps',
  'chains',
  'pipe-stakes',
  'straps',
  'team-driver',
  'tanker-endorsement',
]
const refrigeratedAccessorials: EquipmentAccessorialType[] = [
  'food-grade',
  'temp',
  'team-driver',
  'tanker-endorsement',
]

const boxTruckAccessorials: EquipmentAccessorialType[] = [
  ...defaultAccessorials,
  'liftgate',
  'straps',
]
const straightTruckAccessorials: EquipmentAccessorialType[] = [
  ...defaultAccessorials,
  'liftgate',
  'straps',
]

export const equipmentTypes: Array<{
  key: EquipmentType
  name: string
  accessorialTypes?: EquipmentAccessorialType[]
  workflows: Workflow[]
  mode: Mode
}> = [
  {
    key: 'dry-van-53',
    name: "Dry van 53'",
    accessorialTypes: dryVanAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'dry-van-48',
    name: "Dry van 48'",
    accessorialTypes: dryVanAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'flatbed-53',
    name: "Flatbed 53'",
    accessorialTypes: flatbedAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'flatbed-48',
    name: "Flatbed 48'",
    accessorialTypes: flatbedAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'refrigerated-48',
    name: "Refrigerated 48'",
    workflows: ['truckload'],
    accessorialTypes: refrigeratedAccessorials,
    mode: 'truckload',
  },
  {
    key: 'refrigerated-53',
    name: "Refrigerated 53'",
    workflows: ['truckload'],
    accessorialTypes: refrigeratedAccessorials,
    mode: 'truckload',
  },
  {
    key: 'step-deck',
    name: 'Step deck',
    accessorialTypes: flatbedAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'flatbed-lowboy',
    name: 'Lowboy',
    accessorialTypes: flatbedAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'air-freight',
    name: 'Air freight',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'box-truck-16',
    name: "Box truck 16'",
    accessorialTypes: boxTruckAccessorials,
    workflows: ['truckload', 'ltl'],
    mode: 'truckload',
  },
  {
    key: 'box-truck-24',
    name: "Box truck 24'",
    accessorialTypes: boxTruckAccessorials,
    workflows: ['truckload', 'ltl'],
    mode: 'truckload',
  },
  {
    key: 'bulk-aluminum',
    name: 'Bulk aluminum',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'bulk-auger-semi',
    name: 'Bulk auger (semi)',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'bulk-auger-straight-truck',
    name: 'Bulk auger (straight truck)',
    accessorialTypes: straightTruckAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'bulk-belt-trailer',
    name: 'Bulk belt trailer',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'bulk-convertible-hopper',
    name: 'Bulk convertible hopper',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'bulk-end-dump',
    name: 'Bulk end dump',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'bulk-hopper',
    name: 'Bulk hopper',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'bulk-insulated',
    name: 'Bulk insulated',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'bulk-lined',
    name: 'Bulk lined',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'bulk-one-tank',
    name: 'Standard bulk trailer',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'bulk-pneumatic',
    name: 'Bulk pneumatic',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'bulk-stainless',
    name: 'Bulk stainless',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'bulk-three-hole',
    name: 'Bulk three compartment',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'bulk-four-hole',
    name: 'Bulk four compartment',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'bulk-two-hole',
    name: 'Bulk two compartment',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'bulk-walking-floor',
    name: 'Bulk walking floor',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'bulk-bag-combo',
    name: 'Bulk/bag combo',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'conestoga',
    name: 'Conestoga',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'consolidated',
    name: 'Consolidated',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'crazy8-flatbed',
    name: 'Crazy 8 flatbed',
    accessorialTypes: flatbedAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'curtain-side-van',
    name: 'Curtain side van',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'drayage-20',
    name: "Drayage 20'",
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'drayage-40',
    name: "Drayage 40'",
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'drayage-45',
    name: "Drayage 45'",
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'drayage-48',
    name: "Drayage 48'",
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'drayage-53',
    name: "Drayage 53'",
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'flatbed-48-double-drop',
    name: "Flatbed 48' double drop",
    accessorialTypes: flatbedAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'flatbed-48-step',
    name: "Step deck 48'",
    accessorialTypes: flatbedAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'flatbed-53-step',
    name: "Step deck 53'",
    accessorialTypes: flatbedAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'flatbed-b-train',
    name: 'Flatbed B-train',
    accessorialTypes: flatbedAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'flatbed-heavy-haul',
    name: 'Flatbed heavy haul',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'flatbed-removable-gooseneck(rgn)',
    name: 'Gooseneck Trailer',
    accessorialTypes: flatbedAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'flatbed-self-unloader',
    name: 'Flatbed self unloader',
    accessorialTypes: flatbedAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'flatbed-with-piggyback',
    name: 'Flatbed with piggyback',
    accessorialTypes: flatbedAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'flatbed-tri-axle',
    name: 'Flatbed tri axle',
    accessorialTypes: flatbedAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'hotshot',
    name: 'Hotshot',
    accessorialTypes: flatbedAccessorials,
    workflows: ['truckload', 'ltl'],
    mode: 'truckload',
  },
  {
    key: 'intermodal-20',
    name: "Intermodal 20'",
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'intermodal',
  },
  {
    key: 'intermodal-40',
    name: "Intermodal 40'",
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'intermodal',
  },
  {
    key: 'intermodal-45',
    name: "Intermodal 45'",
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'intermodal',
  },
  {
    key: 'intermodal-48',
    name: "Intermodal 48'",
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'intermodal',
  },
  {
    key: 'intermodal-53',
    name: "Intermodal 53'",
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'intermodal',
  },
  {
    key: 'logistics-van-53',
    name: "Logistics van 53'",
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'ltl',
    name: 'Less than truckload',
    workflows: ['ltl'],
    mode: 'ltl',
    accessorialTypes: ['protect-from-freezing'],
  },
  {
    key: 'parcel',
    name: 'Parcel',
    workflows: ['parcel'],
    mode: 'parcel',
    accessorialTypes: [],
  },
  {
    key: 'padded-van',
    name: 'Padded van',
    workflows: ['truckload'],
    mode: 'truckload',
    accessorialTypes: defaultAccessorials,
  },
  {
    key: 'partial-dry-van',
    name: 'Partial dry van',
    accessorialTypes: [...defaultAccessorials, 'liftgate'],
    workflows: ['truckload', 'ltl'],
    mode: 'truckload',
  },
  {
    key: 'partial-flatbed',
    name: 'Partial flatbed',
    accessorialTypes: flatbedAccessorials,
    workflows: ['truckload', 'ltl'],
    mode: 'truckload',
  },
  {
    key: 'partial-refrigerated',
    name: 'Partial refrigerated',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload', 'ltl'],
    mode: 'truckload',
  },
  {
    key: 'power-only',
    name: 'Power only',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'pup',
    name: 'Pup',
    accessorialTypes: defaultAccessorials,
    workflows: ['ltl'],
    mode: 'ltl',
  },
  {
    key: 'refrigerated-ltl',
    name: 'Refrigerated LTL',
    workflows: ['ltl'],
    mode: 'ltl',
  },
  {
    key: 'sprinter-van',
    name: 'Sprinter van',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload', 'ltl'],
    mode: 'truckload',
  },
  {
    key: 'straight-truck-20',
    name: "Straight truck 20'",
    accessorialTypes: straightTruckAccessorials,
    workflows: ['truckload', 'ltl'],
    mode: 'truckload',
  },
  {
    key: 'straight-truck-26',
    name: "Straight truck 26'",
    accessorialTypes: straightTruckAccessorials,
    workflows: ['truckload', 'ltl'],
    mode: 'truckload',
  },
  {
    key: 'tanker',
    name: 'Tanker',
    accessorialTypes: defaultAccessorials,
    workflows: ['truckload'],
    mode: 'truckload',
  },
  {
    key: 'volume-ltl',
    name: 'Volume LTL',
    workflows: ['ltl'],
    mode: 'ltl',
  },
]

export const displayEquipment = (equipmentType: EquipmentType): string => {
  return (
    equipmentTypes.find(e => e.key === equipmentType)?.name || equipmentType
  )
}

export const getEquipmentMode = (equipmentType: EquipmentType): Mode => {
  return equipmentTypes.find(e => e.key === equipmentType)?.mode
}

export const vEquipmentType = (worfklow?: Workflow) =>
  vPicklist(
    equipmentTypes
      .filter(e => !worfklow || e.workflows?.includes(worfklow))
      .map(e => e.key),
  )
