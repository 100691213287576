import { ObjectId } from '@freightview/object-id'

import { LoadBidExpiration } from './bid-expiration'
import { CarrierTrackingDTO } from './carrier-tracking'
import { DistanceUOM, ShipmentDirection, Workflow } from './common'
import { LoadCustomsBroker } from './customs-broker'
import { DeliveryOption } from './delivery-option'
import { HoldAtLocation } from './hold-at-location'
import { LoadBillTo } from './load-bill-to'
import { LoadBOLDetail } from './load-bol'
import { LoadCancelDetail } from './load-cancel-detail'
import { LoadDocumentDTO } from './load-document'
import { LoadEmergencyContact } from './load-emergency-contact'
import { LoadEquipment } from './load-equipment'
import { LoadItem } from './load-item'
import { LoadLocation } from './load-location'
import { LoadPickupDetail } from './load-pickup'
import { LoadShare } from './load-share'
import { FullLoadTag } from './load-tag'
import { MessageSummary } from './message'
import { MissedPickup } from './missed-pickup'
import { QuoteDTO } from './quote'
import { QuoteSelectionReason } from './quote-selection-reason'
import { LoadRefNum } from './ref-num'
import { TrackDetail } from './track'
import { UserInfo } from './user-info'

export const shipmentStatuses = [
  'pending',
  'awarded',
  'confirmed',
  'picked-up',
  'delivered',
  'canceled',
] as const
export type ShipmentStatus = (typeof shipmentStatuses)[number]

export const loadSources = ['legacy', 'app', 'v1', 'v2', 'upload'] as const
export type LoadSource = (typeof loadSources)[number]
export type QuoteProcessStatus =
  | 'ok' // Finished rating or finished rerating with no further action needed by shipper
  | 'requesting' // Currently fetching quotes from hydra/contracted rates repo
  | 'rerate-ready' // Found a comparible quote, but price is different
  | 'rerating' // Show overlay
  | 'rerate-no-compat' // Rerated and no comparible quotes found
export interface LoadCreatedBy {
  userId: string
  accountId: string
  company: string
  name?: string
  email?: string
  adminUserId?: string
  vendorId?: string
}
export interface LoadBookedBy extends UserInfo {}

export interface ShipperShipment {
  loadId: string
  status: ShipmentStatus
  canceledByUserId?: string
  accountId: string
  quotedByUserId: string
  quotedByAdminUserId?: string
  newMessageCount: number
  tracking?: TrackDetail

  cheapestQuote?: QuoteDTO
  selectedQuote?: QuoteDTO

  messageSummary?: MessageSummary
  createdDate: Date

  hasRecipients?: boolean
  estimatedDeliveryDate?: Date
  targetPrice?: number

  _id: ObjectId
  billTo?: LoadBillTo
  bol: LoadBOLDetail
  bookedBy?: LoadBookedBy
  bookedDate?: Date
  createdBy?: LoadCreatedBy
  currentQuoteVersion?: number
  direction: ShipmentDirection
  distance?: number
  distanceUOM?: DistanceUOM
  documents: LoadDocumentDTO[]
  equipment: LoadEquipment
  isArchived: boolean
  isLegacy?: boolean
  isLiveLoad: boolean
  isMultiTruck: boolean
  items: LoadItem[]
  lastUpdatedDate?: Date
  locations: LoadLocation[]
  pickup: LoadPickupDetail
  pickupDate: Date
  quoteProcessStatus?: QuoteProcessStatus
  quoteSelectionReason?: QuoteSelectionReason
  quoteRequestId: string
  refNums: LoadRefNum[]
  shares?: LoadShare[]
  source: LoadSource
  workflow: Workflow
  _m?: number
  _v?: number
  bidExpiration?: LoadBidExpiration
  isFreightCollect?: boolean
  vendorName?: string
}

export interface FullShipperShipment extends ShipperShipment {
  customsBroker?: LoadCustomsBroker[]
  emergencyContact?: LoadEmergencyContact
  tags?: FullLoadTag[]
  cancel?: Pick<LoadCancelDetail, 'canceledByUserId' | 'status' | 'mode'> & {
    errorMessage: string
  }
  carrierTracking?: CarrierTrackingDTO | null
  holdAtLocation?: HoldAtLocation
  deliveryOption?: DeliveryOption
  schedulePickup: boolean
  missedPickup?: MissedPickup
}

export const isHazardous = (
  load: Pick<ShipperShipment, 'items' | 'equipment'>,
) => {
  if (load.items?.length) {
    return load.items.some(
      i => i.hazardous?.length || i.contains?.some(c => c.hazardous?.length),
    )
  }

  return load.equipment.isHazardous ?? false
}
