import toast from 'react-hot-toast'

import { Icon } from '@fv/client-components'

import { AdminButton } from '../../../components/shared/AdminButton'
import { AdminSection } from '../../../components/shared/AdminSection'
import { AdminTable } from '../../../components/shared/AdminTable'
import { type AdminLoadSpotQuoteRecipient } from '../../../types/admin-load'
import { ViewAsCarrierLink } from '../../carrier-users/ViewAsCarrierLink'
import {
  useAddSpotQuoteRecipient,
  useRemoveSpotQuoteRecipient,
} from '../../dispatches/mutations'
import { type LoadPanelProps } from './types'

export function SpotQuoteRecipientsPanel({ load }: LoadPanelProps) {
  const addRecipient = useAddSpotQuoteRecipient(load._id)
  const removeRecip = useRemoveSpotQuoteRecipient(load._id)

  const handleRemoveClick = async ({ userId }: AdminLoadSpotQuoteRecipient) => {
    if (!window.confirm('Are you sure you want to remove this recipient?')) {
      return
    }
    await removeRecip.mutateAsync({ userId })
  }

  return (
    <AdminSection
      className=""
      title="Spot Quote Recipients"
      headerActions={
        <AdminButton
          icon="user"
          className="btn-sm"
          onClick={e => {
            e.preventDefault()
            const email = prompt('Enter user email')

            if (email === null || addRecipient.isLoading) return
            if (!email?.trim()) {
              toast.error('Recipient email is required.')
              return
            }

            addRecipient.mutateAsync(email)
          }}
        >
          Add recipient
        </AdminButton>
      }
    >
      <AdminTable
        data={load.spotQuoteRecipients}
        rowKey={d => d.userId}
        columns={[
          {
            key: '',
            label: 'Email',
            render: r => (
              <>
                {r.email} -{' '}
                <ViewAsCarrierLink
                  userId={r.userId}
                  redirect={`/search?text=${load._id}`}
                />
              </>
            ),
          },
          {
            key: 'carrier',
            label: 'Carrier',
            render: r =>
              r.carrierId ? (
                <span>
                  {r.carrierName} ({r.carrierId})
                </span>
              ) : null,
          },
          {
            key: 'hasResponded',
            label: 'Responded',
            render: r => <>{r.hasResponded && <Icon icon="check" />}</>,
          },
          {
            key: 'hasDeclined',
            label: 'Declined',
            render: r => <>{r.hasDeclined && <Icon icon="check" />}</>,
          },
          {
            key: 'isArchived',
            label: 'Archived',
            render: r => <>{r.isArchived && <Icon icon="check" />}</>,
          },
          {
            key: 'actions',
            label: '',
            render: r => (
              <div className="flex justify-end">
                <AdminButton
                  tooltip="Remove this recipient"
                  className="btn-sm btn-error"
                  icon="trash"
                  onClick={() => handleRemoveClick(r)}
                />
              </div>
            ),
          },
        ]}
      />
    </AdminSection>
  )
}
