import orderBy from 'lodash/orderBy'
import uniqueId from 'lodash/uniqueId'
import { useMemo, useState } from 'react'

import { type PropsWithClassName } from '@fv/client-types'
import { AdminDate } from '@/components/shared/AdminDate'

import { AdminSliderPanel } from '../../../components/AdminSliderPanel'
import { AdminAlert } from '../../../components/shared/AdminAlert'
import { AdminButton } from '../../../components/shared/AdminButton'
import { AdminCode } from '../../../components/shared/AdminCode'
import { AdminCollapse } from '../../../components/shared/AdminCollapse'
import { AdminLoading } from '../../../components/shared/AdminLoading'
import { AdminSection } from '../../../components/shared/AdminSection'
import {
  type Trace,
  type Transaction,
  useFetchTrace,
  useFetchTraces,
} from '../../../hooks/useFetchTraces'
import { type LoadPanelProps } from './types'

export function TracesPanel({
  load,
  className,
}: PropsWithClassName<LoadPanelProps>) {
  const traces = useFetchTraces(load._id)
  if (!traces.data?.length) {
    return null
  }

  return (
    <AdminSection className={className} title="Traces">
      {orderBy(traces.data, trace => trace.timeStamp).map(trace => (
        <AdminCollapse
          key={trace.traceId}
          className="bg-base-200 w-full"
          titleClassName="text-l flex"
          title={
            <>
              <div>
                <AdminDate val={trace.timeStamp} />
              </div>
              <div className="divider divider-horizontal" />
              <div className="capitalize">
                {trace.transactions[0]?.layer || 'unknown'} {trace.category}
              </div>
              <div className="divider divider-horizontal" />
              <div>{trace.action}</div>
            </>
          }
        >
          <div className="flex gap-2 w-full overflow-x-auto">
            {trace.transactions?.length > 0 &&
              trace.transactions.map(t => (
                <TransactionSlider
                  key={uniqueId(`${trace.traceId}-${t.id}`)}
                  trace={trace}
                  transaction={t}
                  loadId={load._id}
                />
              ))}
          </div>
        </AdminCollapse>
      ))}
    </AdminSection>
  )
}

type SliderProps = {
  loadId: string
  trace: Trace
  transaction: Transaction | null
}
const TransactionSlider = ({ trace, transaction, loadId }: SliderProps) => {
  const [isOpen, setOpen] = useState(false)
  const fullTrace = useFetchTrace(loadId, trace.traceId, isOpen)
  const fullTransaction = useMemo(() => {
    return fullTrace.data?.transactions?.find(
      t => t.id === transaction?.id && t.timeStamp === transaction?.timeStamp,
    )
  }, [fullTrace.data?.transactions, transaction?.id, transaction?.timeStamp])
  const label =
    transaction?.layer === 'carrier'
      ? trace.carrier?.code || transaction.id || transaction.layer
      : transaction?.layer

  if (!transaction) {
    return null
  }

  return (
    <>
      <AdminButton className="btn-sm" onClick={() => setOpen(true)}>
        {label}
      </AdminButton>
      <AdminSliderPanel
        isOpen={isOpen}
        closePanel={() => setOpen(false)}
        width="60rem"
      >
        {fullTrace.isLoading && <AdminLoading />}

        {!fullTrace.isLoading && (
          <>
            {!!transaction.error && (
              <AdminAlert title="This transaction failed with the following error:">
                <p>{transaction.error}</p>
                {!!transaction.errorStack && (
                  <span className="whitespace-pre-wrap pl-4">
                    {transaction.errorStack ?? ''}
                  </span>
                )}
              </AdminAlert>
            )}

            <div>
              <p className="mb-2 capitalize">Request to {label}:</p>
              <AdminCode data={fullTransaction?.request} />
            </div>
            <div className="divider" />
            <div>
              <p className="mb-2 capitalize">Response from {label}:</p>
              <AdminCode data={fullTransaction?.response} />
            </div>
          </>
        )}
      </AdminSliderPanel>
    </>
  )
}
