import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { FvDate, Icon, QuoteCarrier } from '@fv/client-components'
import { type RefNum, type ShipmentLocation } from '@fv/client-types'
import { AdminDate } from '@/components/shared/AdminDate'

import { AdminSliderPanel } from '../../components/AdminSliderPanel'
import InfoBox from '../../components/InfoBox'
import { AdminButton } from '../../components/shared/AdminButton'
import { AdminFormGroup } from '../../components/shared/AdminFormGroup'
import { AdminLink } from '../../components/shared/AdminLink'
import { AdminSection } from '../../components/shared/AdminSection'
import { LoadBadgeList } from '../../components/shared/LoadBadges'
import { useFetchLoad } from '../../hooks/useFetchLoad'
import { adminRoutes } from '../../routes'
import { buildLoadLink, buildLogRocketSearchLink } from '../../utils'
import { ShipperViewAsLink } from '../accounts/components/ShipperViewAsLink'
import { useDispatchLoads } from '../dispatches/mutations'
import { useVendorOverview } from '../vendors/queries'
import { CancelPanel } from './load-detail/CancelPanel'
import { DangerPanel } from './load-detail/DangerPanel'
import { DispatchesPanel } from './load-detail/Dispatches'
import { DocumentsPanel } from './load-detail/DocumentsPanel'
import { InternalDispatchForm } from './load-detail/InternalDispatchForm'
import { InvoicePanel } from './load-detail/InvoicePanel'
import { NotesPanel } from './load-detail/NotesPanel'
import { ProForm } from './load-detail/ProForm'
import { QuotesPanel } from './load-detail/QuotesPanel'
import { SerializeSlider } from './load-detail/SerializeSlider'
import { SpotQuoteRecipientsPanel } from './load-detail/SpotQuoteRecipientsPanel'
import { TicketSlider } from './load-detail/TicketSlider'
import { TracesPanel } from './load-detail/TracesPanel'
import { TrackingPanel } from './load-detail/TrackingPanel'
import { type LoadPanelProps } from './load-detail/types'

export const LoadDetail = () => {
  const { loadId } = useParams<{ loadId?: string }>()
  const loadQuery = useFetchLoad(loadId)
  const load = loadQuery?.data

  if (loadQuery.isLoading && loadQuery.isFetching) return <Loading />
  if (!load) return null

  return (
    <main className="grid grid-cols-1 gap-5">
      <MainDetail load={load} onChange={() => loadQuery.refetch()} />
      <CancelPanel load={load} onChange={() => loadQuery.refetch()} />
      <InvoicePanel load={load} />
      <DispatchesPanel load={load} onChange={() => loadQuery.refetch()} />
      <div className="grid grid-cols-1 gap-5 xl:grid-cols-2">
        <DocumentsPanel load={load} onChange={() => loadQuery.refetch()} />
        <TrackingPanel load={load} onChange={() => loadQuery.refetch()} />
      </div>
      <NotesPanel load={load} />
      <QuotesPanel load={load} />
      <SpotQuoteRecipientsPanel load={load} />
      <TracesPanel load={load} />

      <AdminSection
        title="Profinder"
        headerActions={<SerializeSlider loadId={load._id} output="profinder" />}
      />
      <DangerPanel load={load} />
    </main>
  )
}

function MainDetail({ load, onChange }: LoadPanelProps) {
  const dispatchLoads = useDispatchLoads()
  const [showProForm, setShowProForm] = useState(false)
  const [showInternalForm, setShowInternalForm] = useState(false)
  const vendorQuery = useVendorOverview(load.createdBy?.vendorId)
  const origin = load.locations.find(
    (loc: ShipmentLocation) => loc.type === 'origin',
  )
  const destination = load.locations.find(
    (loc: ShipmentLocation) => loc.type === 'destination',
  )

  const isTimeout =
    load.dispatch?.status === 'requesting' &&
    load.dispatch.errors?.some(e => e.message.includes('Timeout'))
  const needsDispatched =
    load.dispatch?.method === 'internal' &&
    (load.dispatch.status === 'pending' ||
      load.dispatch.status === 'not-requested')

  const redispatch = () => {
    dispatchLoads.mutate(
      {
        loadIds: [load._id],
      },
      {
        onSuccess: onChange,
      },
    )
  }
  return (
    <AdminSection
      title={`Load: ${load._id}`}
      headerActions={
        <>
          <LoadBadgeList load={load} />
          <ShipperViewAsLink
            accountId={load.createdBy.accountId}
            user={{
              _id: load.createdBy.userId,
              username: load.createdBy.email,
            }}
            icon="user-circle"
            className="text-xs"
            redirect={buildLoadLink(load)}
          />
          <AdminLink
            href={buildLogRocketSearchLink(load._id)}
            rel="noreferrer"
            target="_blank"
            className="text-xs"
            icon="rocket"
          >
            Log Rocket
          </AdminLink>
          <TicketSlider loadId={load._id} btnClass="btn-xs !ml-auto" />
        </>
      }
    >
      <div className="p-2">
        {isTimeout && (
          <InfoBox
            icon="exclamation-triangle"
            header="This dispatch timed out."
            Actions={() => (
              <AdminButton
                icon="redo"
                onClick={redispatch}
                className="btn-sm"
                loading={dispatchLoads.isLoading}
              >
                I'm sure the carrier timed out. Redispatch now.
              </AdminButton>
            )}
          >
            {' '}
            If you've inspected the logs and are sure the carrier timed out, you
            can redispatch it.
          </InfoBox>
        )}

        {needsDispatched && (
          <InfoBox
            icon="exclamation-triangle"
            header="This load needs to be dispatched internally"
            Actions={() => (
              <div className="flex gap-2">
                <AdminButton
                  icon="redo"
                  onClick={redispatch}
                  className="btn-xs"
                  loading={dispatchLoads.isLoading}
                >
                  Retry automated dispatch
                </AdminButton>
                <AdminButton
                  icon="ellipsis-h"
                  onClick={() => setShowInternalForm(true)}
                  className="btn-xs"
                  loading={dispatchLoads.isLoading}
                >
                  Add Dispatch Response
                </AdminButton>
              </div>
            )}
          />
        )}

        {load.isLegacy && (
          <InfoBox icon="exclamation-triangle" header="Legacy Load">
            Heads up! This is legacy load - some of the information on this page
            might not be completely accurate
          </InfoBox>
        )}

        <div className="grid lg:grid-cols-2 xl:grid-cols-4">
          <div>
            <AdminFormGroup label="Account">
              <AdminLink to={adminRoutes.accountPage(load.createdBy.accountId)}>
                {load.createdBy.company} (Account Admin)
              </AdminLink>
            </AdminFormGroup>
            {load.createdBy?.vendorId && (
              <AdminFormGroup label="Vendor">
                <AdminLink to={adminRoutes.vendorPage(load.createdBy.vendorId)}>
                  {vendorQuery?.data?.name ?? 'unknown'}
                </AdminLink>
              </AdminFormGroup>
            )}

            <AdminFormGroup label="Origin">
              {origin?.city}, {origin?.state} {origin?.postalCode}
            </AdminFormGroup>

            <AdminFormGroup label="Destination">
              {destination?.city}, {destination?.state}{' '}
              {destination?.postalCode}
            </AdminFormGroup>
          </div>
          <div>
            <AdminFormGroup label="Created">
              <AdminDate val={load.createdDate} format="MM/DD/YYYY HH:mm A" />
            </AdminFormGroup>

            <AdminFormGroup label="Booked Date">
              {load.status === 'booked' ? (
                <AdminDate val={load.bookedDate} format="MM/DD/YYYY HH:mm A" />
              ) : (
                <>N/A</>
              )}
            </AdminFormGroup>

            <AdminFormGroup label="Pickup Date">
              <FvDate val={load.pickupDate} format="MM/DD/YYYY" />
            </AdminFormGroup>
            {load.bidExpiration?.utc && (
              <AdminFormGroup label="Bid Expiration Date">
                <FvDate val={load.bidExpiration?.utc} format="MM/DD/YYYY" />
              </AdminFormGroup>
            )}
          </div>
          <div>
            <AdminFormGroup label="Carrier">
              {load.status === 'booked' ? (
                <AdminLink
                  to={adminRoutes.carrierPage(load.selectedQuote?.carrierId)}
                >
                  <QuoteCarrier quote={load.selectedQuote} />
                </AdminLink>
              ) : (
                'N/A'
              )}
            </AdminFormGroup>
            <AdminFormGroup label="Payment Terms">
              {load.status === 'booked'
                ? load.selectedQuote?.paymentTerms
                : 'N/A'}
            </AdminFormGroup>
            <AdminFormGroup label="Dispatch Status">
              {load.dispatch?.status ?? 'N/A'}
            </AdminFormGroup>
          </div>
          <div>
            <AdminFormGroup label="BOL Number">
              {load.bol?.bolNumber ?? 'N/A'}
            </AdminFormGroup>
            <AdminFormGroup label="Confirmation Number">
              {load.pickup?.confirmationNumber ?? 'N/A'}
            </AdminFormGroup>
            <AdminFormGroup label="Ref Nums">
              {load.refNums?.map((r: RefNum) => r.value).join(' | ') ?? 'n/a'}
            </AdminFormGroup>
            <AdminFormGroup label="Pro Num">
              <AdminLink onClick={() => setShowProForm(true)}>
                {load.track?.trackingNumber
                  ? load.track.trackingNumber
                  : 'Add Pro Number'}
              </AdminLink>
            </AdminFormGroup>
          </div>
        </div>

        <AdminSliderPanel
          isOpen={showProForm}
          closePanel={() => setShowProForm(false)}
        >
          <ProForm
            load={load}
            onChange={() => {
              onChange?.()
              setShowProForm(false)
            }}
          />
        </AdminSliderPanel>
        <AdminSliderPanel
          isOpen={showInternalForm}
          closePanel={() => setShowInternalForm(false)}
        >
          <InternalDispatchForm
            load={load}
            onChange={() => {
              onChange?.()
              setShowInternalForm(false)
            }}
          />
        </AdminSliderPanel>
      </div>
    </AdminSection>
  )
}

function Loading() {
  return (
    <div style={{ fontSize: '2rem', textAlign: 'center' }}>
      <Icon icon="spinner" /> Loading...
    </div>
  )
}
