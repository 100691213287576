import { ForwardedRef, forwardRef, ReactNode } from 'react'

import { copyToClipboard } from '@fv/client-core'

import { FvButton } from './FvButton'
import { TooltipProps } from './Tooltip'

type Props = {
  value: string
  tooltipComponent?: (props: TooltipProps) => ReactNode
}
export const ClipboardButton = forwardRef(
  (
    { value, tooltipComponent }: Props,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    if (!value) {
      return null
    }

    const btn = (
      <FvButton
        ref={ref}
        icon="clone"
        transform="shrink-3"
        onClick={() => copyToClipboard(value)}
      />
    )

    if (!tooltipComponent) return btn

    return tooltipComponent({ label: 'Copy', children: btn })
  },
)
