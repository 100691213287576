import orderBy from 'lodash/orderBy'

import { AdminDate } from '@/components/shared/AdminDate'
import { AdminTooltip } from '@/components/shared/AdminTooltip'

import { AdminSection } from '../../../components/shared/AdminSection'
import { AdminTable } from '../../../components/shared/AdminTable'
import { type LoadPanelProps } from './types'

export const NotesPanel = ({ load }: LoadPanelProps) => {
  return (
    <AdminSection className="" title="Notes">
      <AdminTable
        data={load.notes}
        rowKey={r => r._id}
        columns={[
          {
            key: 'createdDate',
            label: 'Created',
            render: d => (
              <AdminDate val={d.createdDate} format="YYYY-MM-DD hh:mm:ss" />
            ),
          },
          {
            key: 'message',
            label: 'Message',
            render: d => (
              <AdminTooltip label={d.message}>
                <span>{d.message.substring(0, 100)}</span>
              </AdminTooltip>
            ),
          },
          { key: 'source', label: 'Source', render: d => d.source },
          { key: 'type', label: 'Type', render: d => d.type },
          {
            key: 'user',
            label: 'User',
            render: d => d.createdBy?.name,
            sort: (data, dir) => orderBy(data, d => d.createdBy?.name, [dir]),
          },
        ]}
      />
    </AdminSection>
  )
}
