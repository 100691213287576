import clsx from 'clsx'
import dayjs, { ConfigType } from 'dayjs'
import { memo, ReactElement, ReactNode, useMemo } from 'react'

import { Tooltip, TooltipProps } from './Tooltip'

export type FvDateProps = {
  val: ConfigType
  utc?: boolean
  format?: string
  wrap?: boolean
}
export const FvDate = memo(function FvDate({
  val,
  utc = true,
  format = 'MMM D',
  wrap = false,
}: FvDateProps) {
  const date = useMemo(
    () => (utc ? dayjs.utc(val).local() : dayjs(val)).format(format),
    [val, utc, format],
  )
  if (!val) return null
  return (
    <span
      className={clsx({
        'whitespace-nowrap': !wrap,
      })}
    >
      {date}
    </span>
  )
})

type RelativeProps = {
  val: ConfigType
  tooltip?: boolean
  tooltipComponent?: (props: TooltipProps) => ReactNode
}
export const FvRelativeDate = ({
  val,
  tooltip,
  tooltipComponent,
}: RelativeProps) => {
  if (!val) return null

  const date = dayjs.utc(val).local()
  const display = date.fromNow()
  if (!tooltip || !tooltipComponent) {
    return <span>{display}</span>
  }

  return tooltipComponent({
    label: date.format('YYYY-MM-DD h:mma'),
    children: <span>{display}</span>,
  })
}
