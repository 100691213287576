import dayjs from 'dayjs'
import defaultsDeep from 'lodash/defaultsDeep'
import isNil from 'lodash/isNil'
import {
  any,
  array,
  type BaseIssue,
  type BaseSchema,
  boolean,
  check,
  date,
  fallback,
  forward,
  GenericSchema,
  getFallbacks,
  type InferOutput,
  integer,
  isoDate,
  LengthInput,
  literal,
  maxLength,
  maxValue,
  minLength,
  minValue,
  nullish,
  number,
  object,
  type ObjectEntries,
  omit,
  pick,
  picklist,
  type PicklistOptions,
  pipe,
  rawCheck,
  regex,
  required,
  strictObject,
  string,
  transform,
  union,
  ValueInput,
  variant,
} from 'valibot'

import { allowEmptyStringRegex, domainRegex, emailRegex } from '@fv/models/core'

export const vObject = <T extends ObjectEntries>(
  entries: T,
  errorMessage?: string,
) => object(entries, errorMessage)

export const vStrictObject = <T extends ObjectEntries>(
  entries: T,
  errorMessage = 'Not a valid property',
) => strictObject(entries, errorMessage)

export const vAny = any
export const vArray = array
export const vPipe = pipe
export const vCheck = check
export const vTransform = transform
export const vOmit = omit
export const vPick = pick
export const vOneOf = union
export const vRequired = required
export const vLiteral = literal
export const vUnion = union
export const vVariant = variant
export const vRawCheck = rawCheck
export const vBoolean = () =>
  pipe(
    union([boolean(), picklist(['true', 'false'])]),
    transform(v => (typeof v === 'boolean' ? v : v === 'true')),
  )

export const vBoolStrict = () => boolean()

export const vDefaults = <TSchema extends GenericSchema>(
  schema: TSchema,
  defaults: Partial<InferOutput<TSchema>>,
) =>
  pipe(
    schema,
    transform(v => defaultsDeep(v, defaults)),
  )

export const vString = (errorMessage?: string) =>
  pipe(
    string(errorMessage),
    transform(v => {
      return isNil(v) ? v : String(v)
    }),
  )

export const vObjectId = () => pipe(vString(), minLength(24), maxLength(24))

export const vPicklist = <const TOptions extends PicklistOptions>(
  options: TOptions,
) => picklist(options, `Must be one of ${options.join(', ')}`)

export const vCoerceArray = <T extends GenericSchema>(schema: T) =>
  union([
    pipe(
      schema,
      transform(v => [v]),
    ),
    array(schema),
  ])

export const vAddFields = <
  TOutput extends Record<string, unknown>,
  T extends BaseSchema<unknown, TOutput, BaseIssue<unknown>>,
  TFields,
>(
  schema: T,
  fields: TFields,
) => {
  return pipe(
    schema,
    transform(v => ({ ...v, ...fields })),
  )
}

export const vDomain = pipe(
  string(),
  regex(domainRegex),
  transform(v => (v ? v.toLowerCase().trim() : v)),
)

export const vEmail = pipe(
  string(),
  regex(emailRegex),
  transform(v => (v ? v.toLowerCase().trim() : v)),
)

export const vEmailOrEmpty = pipe(
  string(),
  regex(allowEmptyStringRegex(true, emailRegex)),
  transform(v => (v ? v.toLowerCase().trim() : v)),
)

export const vNumber = () =>
  pipe(
    number(),
    transform(v => (v ? Number(v) : v)),
  )

export const vOptional = <TSchema extends GenericSchema>(wrapped: TSchema) =>
  nullish(wrapped)

export const vOptionalDef = <
  TSchema extends GenericSchema,
  TDefault extends InferOutput<TSchema>,
>(
  wrapped: TSchema,
  def?: TDefault,
) => nullish(wrapped, def) // unwrap makes it so the types generated from this indicate the correct null type

export const vFallback = fallback
export const createDefault = getFallbacks
export const vFallbackObj = <const TSchema extends GenericSchema>(
  wrapped: TSchema,
) => vFallback(wrapped, createDefault(wrapped))

export const vMaxLength = <
  TInput extends LengthInput,
  const TRequirement extends number,
>(
  value: TRequirement,
) =>
  maxLength<TInput, TRequirement, string>(
    value,
    `Must have length less than or equal to ${value.toString()}.`,
  )

export const vMinLength = <
  TInput extends LengthInput,
  const TRequirement extends number,
>(
  value: TRequirement,
  message?: string,
) =>
  minLength<TInput, TRequirement, string>(
    value,
    message || `Must have length greater than or equal to ${value.toString()}.`,
  )

export const vMinValue = <
  TInput extends ValueInput,
  const TRequirement extends TInput,
>(
  value: TRequirement,
  message?: string,
) =>
  minValue<TInput, TRequirement, string>(
    value,
    message || `Must be greater than or equal to ${value.toString()}.`,
  )

export const vMaxValue = <
  TInput extends ValueInput,
  const TRequirement extends TInput,
>(
  value: TRequirement,
) =>
  maxValue<TInput, TRequirement, string>(
    value,
    `Must be less than or equal to ${value.toString()}.`,
  )

export const vRegex = (r: RegExp, errorMessage?: string) =>
  regex(r, errorMessage || 'Invalid value provided')

export const vInt = integer
export const vISODate = isoDate
export const vISODateTime = () =>
  regex(
    /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/,
  )

export const vNonEmptyString = () => pipe(string(), vMinLength(1))

export const vDate = () =>
  pipe(
    union([string(), date()]),
    transform(v => {
      if (!v) {
        return null
      }

      return dayjs.utc(v as string).toDate()
    }),
  )

export const vStringToNumber = () =>
  vPipe(
    vUnion([vString(), vNumber()]),
    vTransform(v => Number(v)),
  )

export const vNumberToString = () =>
  vPipe(
    vUnion([vNumber(), vString()]),
    vTransform(v => v.toString()),
  )

export const vContToken = () => vOptional(vString())

export const vForward = forward
