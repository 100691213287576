import { cleanPhoneNumber } from '@freightview/string-helpers'
import { transform } from 'valibot'

import { packagingTypes, packingGroups } from '../..'
import {
  formatPhone,
  militaryToStandard,
  parsePhone,
  standardToMilitary,
} from '../../core'
import { dates } from '../../core/dates'
import {
  allowEmptyStringRegex,
  combineRegexWithOr,
} from '../../core/regex-utils'
import {
  Currency,
  currency,
  dimensionsUOM,
  FreightClass,
  freightClasses,
  hazardClasses,
  hazardSubClasses,
  modes,
  weightUOM,
  Workflow,
  workflows,
} from '../../models/common'
import { countries, CountryMapper } from '../../models/country'
import {
  vInt,
  vMaxLength,
  vMinLength,
  vMinValue,
  vNumberToString,
  vOptional,
  vOptionalDef,
  vPicklist,
  vPipe,
  vRegex,
  vString,
  vStringToNumber,
  vTransform,
} from './core'

const dateRegex = (allowEmptyString: boolean) =>
  allowEmptyStringRegex(
    allowEmptyString,
    combineRegexWithOr(
      /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/, //2023-05-09
      /^\d{1,2}[-/]\d{1,2}[-/]\d{4}$/, // 05/09/2023
      /^\d{4}[-/]\d{1,2}[-/]\d{1,2}$/, // 2023-5-9
    ),
  )

export const vMode = vPicklist(modes)
export const vCountry = vPicklist(countries.map(c => c.key))
export const vCountryUpper = vPicklist(countries.map(c => c.name))
const anyCountrySchema = () =>
  vPicklist([
    ...countries.map(c => c.key),
    ...countries.map(c => c.name),
    'USA',
    'MEX',
    'CAN',
    'usa',
    'mex',
    'can',
  ])
export const vCountryAny = vPipe(
  anyCountrySchema(),
  transform(value => (value ? CountryMapper.legacyToCore(value) : undefined)),
)

export const vCountryLTL = vPicklist([
  ...countries.filter(c => c.key !== 'mx').map(c => c.key),
])
export const vMilitaryTimeStrict = vPipe(
  vString(),
  vRegex(/([01]?[0-9]|2[0-3]):[0-5][0-9]/),
)
export const vCountryAnyToUpper = () =>
  vPipe(
    anyCountrySchema(),
    transform(value => (value ? CountryMapper.coreToLegacy(value) : undefined)),
  )
export const vPhoneExt = () => vPipe(vString(), transform(cleanPhoneNumber))
export const vPhoneNumber = () =>
  vPipe(vNumberToString(), transform(parsePhone))

export const vPhoneStrict = () =>
  vPipe(
    vString(),
    vRegex(/^\d+$/, 'Phone must only contain digits'),
    vMinLength(10),
    vMaxLength(12),
    transform(parsePhone),
  )
export const vFormattedPhoneNumber = () =>
  vPipe(vString(), transform(formatPhone))
export const vWeightUOM = vPicklist(weightUOM)
export const vCurrency = (defaultValue?: Currency) =>
  vPipe(
    vOptionalDef(
      vPicklist([...currency.map(c => c.key), ...currency.map(c => c.name)]),
      defaultValue,
    ),
    vTransform(value => value.toLowerCase() as Currency),
  )

export const vDateUtcNoon = (allowEmptyString = false) =>
  vPipe(
    vString(),
    vRegex(dateRegex(allowEmptyString), 'Invalid date'),
    vTransform(v => {
      if (!v) {
        return undefined
      }

      return dates.utcNoon(v as string)
    }),
  )

export const vDateUtcNoonStrict = vPipe(
  vString(),
  vRegex(/^\d{4}[-/]\d{1,2}[-/]\d{1,2}$/, 'Invalid date'),
  vTransform(v => {
    if (!v) {
      return undefined
    }

    return dates.utcNoon(v as string)
  }),
)

export const vPostalCode = (allowEmptyString = false) =>
  vPipe(
    vString(),
    vRegex(
      allowEmptyStringRegex(
        allowEmptyString,
        /(^\d{5}([ :-]?\d{4})?)$|(^[A-z]\d[A-z][ :-]?\d[A-z]\d$)/,
      ),
      'Invalid postal code',
    ),
    vTransform(v => (v ? String(v).toUpperCase() : v)),
  )

export const vWorkflow = () => vPicklist([...workflows])
export const vDimensionsUOM = () => vPicklist(dimensionsUOM)
export const vFreightClass = () =>
  vPipe(
    vPicklist(freightClasses),
    vTransform(v => Number(v) as FreightClass),
  )

export const vPackingGroup = () =>
  vPipe(
    vOptional(
      vPicklist([
        ...packingGroups.map(p => p.name),
        ...packingGroups.map(p => p.key),
      ]),
    ),
    vTransform(
      value =>
        packingGroups.find(p => p.name === value || p.key === value)?.key,
    ),
  )

export const vPrimaryHazardClass = () =>
  vPicklist(hazardClasses.map(h => h.key))
export const vSubHazardClass = () => vPicklist(hazardSubClasses)

export const vPackagingType = (workflow?: Workflow) =>
  vPicklist(
    packagingTypes
      .filter(p => !workflow || p.modes.includes(workflow))
      .map(p => p.key),
  )

export const vContainerPackagingType = (workflow?: Workflow) =>
  vPicklist(
    packagingTypes
      .filter(p => p.isContainer && (!workflow || p.modes.includes(workflow)))
      .map(p => p.key),
  )

export const vContainablePackagingType = (workflow?: Workflow) =>
  vPicklist(
    packagingTypes
      .filter(
        p => !!p.canBeContained && (!workflow || p.modes.includes(workflow)),
      )
      .map(p => p.key),
  )

export const vAtLeastOne = () => vPipe(vStringToNumber(), vMinValue(1))
export const vAtLeastOneInt = () =>
  vPipe(vStringToNumber(), vInt(), vMinValue(1))

export const vStandardTime = () =>
  vPipe(
    vString(),
    vTransform(v => militaryToStandard(v)),
  )

export const vMilitaryTime = () =>
  vPipe(
    vString(),
    vTransform(v => standardToMilitary(v)),
  )
