import { camelCaseToTitleCase } from '../core'
import { Mode } from './common'
import { EquipmentAccessorialType } from './equipment-type'
import {
  locationAccessorials,
  LocationAccessorialType,
} from './location-accessorial'

export const accessorialTargets = ['equipment', 'location'] as const

export type AccessorialTarget = (typeof accessorialTargets)[number]

const locationType: AccessorialTarget = 'location'

export interface AccessorialOption {
  label: string
  value: string | number
}

export interface AccessorialPrompt {
  key: string
  label: string
  promptType: 'boolean' | 'string' | 'number'
  min?: number
  max?: number
  options?: AccessorialOption[]
}

export interface AccessorialDetails {
  key: `${EquipmentAccessorialType | LocationAccessorialType}`
  type: AccessorialTarget
  name: string
  prompts?: AccessorialPrompt[]
  mode: Mode
}

export const accessorialsList: AccessorialDetails[] = [
  ...locationAccessorials.map(a => ({
    ...a,
    type: locationType,
    mode: a.mode as unknown as Mode,
    ...(a.key === 'tradeshow' && {
      prompts: [
        {
          key: 'tradeshowName',
          label: 'Name',
          promptType: 'string',
        },
        {
          key: 'tradeshowBooth',
          label: 'Booth',
          promptType: 'string',
        },
      ] as AccessorialPrompt[],
    }),
  })),
  {
    key: 'chains',
    type: 'equipment',
    name: 'Chains',
    prompts: [
      {
        key: 'count',
        label: 'How many?',
        promptType: 'number',
      },
    ],
    mode: 'truckload',
  },
  {
    key: 'e-track',
    type: 'equipment',
    name: 'E-track',
    mode: 'truckload',
  },
  {
    key: 'food-grade',
    type: 'equipment',
    name: 'Food grade',
    mode: 'truckload',
  },
  {
    key: 'load-bars',
    type: 'equipment',
    name: 'Load bars',
    prompts: [
      {
        key: 'count',
        label: 'How many?',
        promptType: 'number',
      },
    ],
    mode: 'truckload',
  },
  {
    key: 'straps',
    type: 'equipment',
    name: 'Straps',
    prompts: [
      {
        key: 'count',
        label: 'How many?',
        promptType: 'number',
      },
    ],
    mode: 'truckload',
  },
  {
    key: 'tarps',
    type: 'equipment',
    name: 'Tarps',
    prompts: [
      {
        key: 'size',
        label: 'What size?',
        promptType: 'number',
        options: [
          {
            label: '4 ft.',
            value: 4,
          },
          {
            label: '6 ft.',
            value: 6,
          },
          {
            label: '8 ft.',
            value: 8,
          },
        ],
      },
    ],
    mode: 'truckload',
  },
  {
    key: 'temp',
    type: 'equipment',
    name: 'Temp control',
    prompts: [
      {
        key: 'min',
        label: 'Min temperature?',
        promptType: 'number',
      },
      {
        key: 'max',
        label: 'Max temperature?',
        promptType: 'number',
      },
      {
        key: 'uom',
        label: 'Unit of measure?',
        promptType: 'string',
        options: [
          {
            label: `${String.fromCharCode(176)}F`,
            value: 'f',
          },
          {
            label: `${String.fromCharCode(176)}C`,
            value: 'c',
          },
        ],
      },
    ],
    mode: 'truckload',
  },
  {
    key: 'team-driver',
    type: 'equipment',
    name: 'Team driver',
    mode: 'truckload',
  },
  {
    key: 'protect-from-freezing',
    type: 'equipment',
    name: 'Protect from freezing',
    mode: 'ltl',
  },
  {
    key: 'liftgate',
    type: 'equipment',
    name: 'Liftgate',
    mode: 'ltl',
  },
  {
    key: 'liftgate',
    type: 'equipment',
    name: 'Liftgate',
    mode: 'truckload',
  },
  {
    key: 'tanker-endorsement',
    type: 'equipment',
    name: 'Tanker endorsement',
    mode: 'truckload',
  },
  {
    key: 'pipe-stakes',
    type: 'equipment',
    name: 'Pipe Stakes',
    mode: 'truckload',
  },
]

export const getAccessorialName = (
  key:
    | EquipmentAccessorialType
    | LocationAccessorialType
    | 'hazardous'
    | 'stop-off',
) =>
  accessorialsList.find(a => a.key === key)?.name || camelCaseToTitleCase(key)
