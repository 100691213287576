import {
  vCoerceArray,
  vEmail,
  vObject,
  vOptional,
  vOutput,
} from '../validation'

export interface SubscriptionPortalResponse {
  redirect: string
}

export type SubscriptionPaymentType =
  | 'card'
  | 'bank_account'
  | 'ach_credit_transfer'

export type SubscriptionStatus =
  | 'active'
  | 'canceled'
  | 'incomplete_expired'
  | 'past_due'
  | 'trial'
  | 'trialing'
  | 'unpaid'
  | 'incomplete'

type ACH_Status =
  | 'new'
  | 'pending'
  | 'verified'
  | 'verification_failed'
  | 'subscribed'

export interface ACHPushInvoiceEntry {
  invoiceNumber: string
  invoiceDate: Date
  poNumber?: string
  lastModifiedUser?: string
  lastModified?: Date
}

export interface AccountSubscription {
  // TODO can we remove ach?  Only thing I can think that we may need is authorized
  ach?: {
    authorized: {
      username: string
      signed: Date | null
    }
    created: Date
    source: string
    status: ACH_Status
    updated: Date
  }

  ach_push?: {
    account_number: string | null // TODO remove
    balance: number
    created: Date
    routing_number: string | null // TODO remove
    source: string | null // TODO Remove
    status: 'new' | 'pending' | 'chargeable'
    updated: Date
    invoiceHistory?: ACHPushInvoiceEntry[]
  }

  customerId?: string
  ends?: Date
  source_type?: SubscriptionPaymentType | null
  quantity?: number
  status: SubscriptionStatus
  plan: string
  plan_id: string
  reserved_plan: string
  lastUnpaidStatus?: Date
  cancel_at_period_end?: boolean
  modified?: Date
  receipt_emails?: string[]
}

export interface SubscriptionFundingDto {
  accountNumber: string
  bankName: string
  routingNumber: string
}

export type PricingIntervalType = 'day' | 'month' | 'week' | 'year'
export type SubscriptionPriceModel = {
  id: string
  amount: number
  interval: PricingIntervalType
  intervalCount: number
  productName: string
}

export interface SubscriptionContextResponse {
  balance?: number
  ends?: Date
  funding?: SubscriptionFundingDto
  needsSetup?: boolean
  receiptEmails?: string[]
  status: SubscriptionStatus
  cancelAtEnd?: boolean
  type: SubscriptionPaymentType
  pricing?: SubscriptionPriceModel
}

export const updateSubscriptionSettingsSchema = vObject({
  receiptEmails: vOptional(vCoerceArray(vEmail)),
})
export type UpdateSubscriptionSettingsDto = vOutput<
  typeof updateSubscriptionSettingsSchema
>
