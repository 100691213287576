import {
  arrow,
  autoUpdate,
  flip,
  FloatingContext,
  FloatingPortal,
  offset,
  ReferenceType,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions'
import clsx from 'clsx'
import {
  cloneElement,
  memo,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'

type TooltipPortalProps = {
  children: JSX.Element
  label: ReactNode
  className?: string
  onlyOnOverflow: boolean
  arrowClassName?: (context: FloatingContext<ReferenceType>) => string
}

// https://floating-ui.com/docs/react-dom-interactions
const TooltipPortal = memo(function TooltipPortal({
  children,
  label,
  onlyOnOverflow,
  className,
  arrowClassName,
}: TooltipPortalProps) {
  const [open, setOpen] = useState(false)
  const arrowRef = useRef<HTMLDivElement>(null)

  const { context, floating, middlewareData, refs, reference, strategy, x, y } =
    useFloating({
      placement: 'top',
      open,
      onOpenChange: setOpen,
      middleware: [
        offset(8),
        flip(),
        shift({ padding: 8 }),
        arrow({ element: arrowRef }),
      ],
      whileElementsMounted: autoUpdate,
    })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ])

  const hasOverflow = useHasOverflow(
    refs.reference as MutableRefObject<Element | null>,
  )

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({ ref: reference, ...children.props }),
      )}

      <FloatingPortal
        id="FVTooltip_portal"
        root={document.getElementById('root')}
      >
        {open && (!onlyOnOverflow || hasOverflow) && (
          <div
            {...getFloatingProps({
              ref: floating,
              style: {
                position: strategy,
                top: y ?? '',
                left: x ?? '',
              },
            })}
            className={clsx('z-[9999] inline-block ', className)}
          >
            {label}
            <div
              className={clsx(
                'absolute h-2 w-2 rotate-45',
                arrowClassName?.(context),
                {
                  '-bottom-1': context.placement === 'top',
                  '-top-1': context.placement === 'bottom',
                },
              )}
              ref={arrowRef}
              style={{ left: `${middlewareData.arrow?.x ?? 0}px` }}
            />
          </div>
        )}
      </FloatingPortal>
    </>
  )
})

export type TooltipProps = {
  children: JSX.Element
  label?: ReactNode
  onlyOnOverflow?: boolean
  arrowClassName?: (context: FloatingContext<ReferenceType>) => string
  className?: string
}

export const Tooltip = memo(function Tooltip({
  children,
  label,
  onlyOnOverflow = false,
  arrowClassName,
  className,
}: TooltipProps) {
  if (!label) return children

  return (
    <TooltipPortal
      label={label}
      onlyOnOverflow={onlyOnOverflow}
      arrowClassName={arrowClassName}
      className={className}
    >
      {children}
    </TooltipPortal>
  )
})

function useHasOverflow(ref: MutableRefObject<Element | null>) {
  const [hasOverflow, setHasOverflow] = useState(false)

  useEffect(() => {
    if (!ref.current) return
    const { clientWidth = 0, scrollWidth = 0 } = ref.current
    setHasOverflow(scrollWidth > clientWidth)
  }, [ref])

  return hasOverflow
}
