import toast from 'react-hot-toast'

import { CarrierTrackingLink, FvDate } from '@fv/client-components'
import { type PropsWithClassName } from '@fv/client-types'
import { AdminDate } from '@/components/shared/AdminDate'

import { KVDisplay } from '../../../components/KVDisplay'
import { AdminButton } from '../../../components/shared/AdminButton'
import { AdminLink } from '../../../components/shared/AdminLink'
import { AdminSection } from '../../../components/shared/AdminSection'
import { AdminTable } from '../../../components/shared/AdminTable'
import { useSyncTracking } from '../../../hooks/useTrackingUpdate'
import { friendlyDate } from '../../../utils'
import { SerializeSlider } from './SerializeSlider'
import { type LoadPanelProps } from './types'

const TrackingHeaderActions = ({ load, onChange }: LoadPanelProps) => {
  const syncTracking = useSyncTracking()
  if (load.isLegacy) return null

  return (
    <>
      <AdminButton
        icon="redo"
        className="btn-sm"
        loading={syncTracking.isLoading}
        onClick={() =>
          syncTracking.mutate(load, {
            onSuccess: () => {
              onChange?.()
              toast.success('Tracking synced successfully')
            },
          })
        }
      >
        Trigger tracking update
      </AdminButton>
      <CarrierTrackingLink
        Button={({ ref, ...props }) => (
          <AdminLink icon="square-arrow-up-right" {...props}>
            External
          </AdminLink>
        )}
        carrierTracking={load.carrierTracking}
      />
      <SerializeSlider loadId={load._id} output="tracking" />
    </>
  )
}

export function TrackingPanel({
  load,
  onChange,
  className,
}: PropsWithClassName<LoadPanelProps>) {
  const events = load.track?.trackEvents ?? []

  return (
    <AdminSection
      className={className}
      title="Tracking"
      headerActions={<TrackingHeaderActions {...{ load, onChange }} />}
    >
      <div className="mb-2 xl:grid xl:grid-cols-4">
        <KVDisplay label="Current status">
          {load.track?.currentStatus ?? 'pending'}
        </KVDisplay>
        <KVDisplay label="Tracking number">
          {load.track?.trackingNumber ?? 'N/A'}
        </KVDisplay>
        <KVDisplay label="Last update">
          <AdminDate val={load.track?.lastUpdatedDate} />
        </KVDisplay>
        <KVDisplay label="Actual pickup">
          {friendlyDate(load.track?.pickupDateActual)}
        </KVDisplay>
        <KVDisplay label="Estimated delivery">
          {friendlyDate(load.track?.deliveryDateEstimate)}
        </KVDisplay>

        <KVDisplay label="Actual delivery">
          {friendlyDate(load.track?.deliveryDateActual)}
        </KVDisplay>
      </div>

      <AdminTable
        data={events}
        rowKey={i => JSON.stringify(i)}
        columns={[
          { key: 'eventType', label: 'Event', render: d => d.eventType },
          { key: 'summary', label: 'Summary', render: d => d.summary },
          {
            key: 'eventSource',
            label: 'Source',
            render: d => `${d.eventSource} (${d.eventMode})`,
          },
          {
            key: 'eventDate',
            label: 'Event Date',
            render: t => <FvDate val={t.eventDate} format="MM/DD/YYYY" />,
          },
          {
            key: 'createdDate',
            label: 'Last Updated',
            render: t => <AdminDate val={t.createdDate} />,
          },
        ]}
      />
    </AdminSection>
  )
}
