import { DTO, HintedString } from '../core'
import { vOutput } from '../validation'
import {
  vArray,
  vBoolean,
  vCoerceArray,
  vDate,
  vObject,
  vOptional,
  vPick,
  vPicklist,
  vString,
} from '../validation/schema/core'
import { workflows } from './common'
import { readyCompletedFilterSchema } from './shipment-filters'
import { SubscriptionPaymentType, SubscriptionStatus } from './subscription'

export const adminExportAccountLoadsSchema = vObject({
  ...vPick(readyCompletedFilterSchema, ['pickupDate', 'status']).entries,
  workflow: vArray(vPicklist(workflows)),
  excludeFedEx: vOptional(vBoolean()),
  emails: vCoerceArray(vString()),
  additionalAccounts: vOptional(vArray(vString())),
})
export type AdminExportAccountLoadsDto = vOutput<
  typeof adminExportAccountLoadsSchema
>

export const adminUpdateSubscriptionSchema = vObject({
  pricing: vOptional(vString()),
  endDate: vOptional(vDate()),
})

export type AdminUpdateSubscriptionDto = vOutput<
  typeof adminUpdateSubscriptionSchema
>

export interface PricingOptionEntry {
  id: string
  name: string
}
export interface AdminPricingOptionsResponse {
  options: PricingOptionEntry[]
}

export interface AdminAccountSubscriptionDTO {
  planId: string
  amount: number
  customerId: string
  ends: Date
  intervalCount: number
  interval: string
  productName: string
  status: SubscriptionStatus
  sourceType: SubscriptionPaymentType
}

export interface InvoiceDataEntry {
  id: InvoiceId
  number: string
  due: Date
  poNumber?: string
  invoiceDate?: Date
  lastModified?: Date
  lastModifiedUser?: string
}
export interface AdminInvoiceData {
  invoiceHistory?: InvoiceDataEntry[]
  upcoming?: InvoiceDataEntry
}

export type InvoiceDataDTO = DTO<AdminInvoiceData>

export type InvoiceId = HintedString<'upcoming'>
export const adminInvoiceUpsertSchema = vObject({
  poNumber: vOptional(vString()),
  issueDate: vOptional(vDate()),
})
export type AdminInvoiceUpsertDto = vOutput<typeof adminInvoiceUpsertSchema>
export const STRIPE_ENV_MISMATCH = 'stripe_env_mismatch'
