import { useState } from 'react'
import toast from 'react-hot-toast'

import { ValidatedForm } from '@fv/client-components'
import { type LoadDocument } from '@fv/client-types'
import { AdminDate } from '@/components/shared/AdminDate'
import { type AdminLoad } from '@/types/admin-load'

import { AdminSliderPanel } from '../../../components/AdminSliderPanel'
import { AdminButton } from '../../../components/shared/AdminButton'
import { AdminLink } from '../../../components/shared/AdminLink'
import { AdminRadio } from '../../../components/shared/AdminRadio'
import { AdminSection } from '../../../components/shared/AdminSection'
import { AdminTable } from '../../../components/shared/AdminTable'
import { useFetchGenericUser } from '../../../hooks/useFetchUsers'
import { useRefreshCarrierBOL } from '../../../hooks/useRefreshCarrierBOL'
import {
  useDeleteLoadDocument,
  useGenerateBOLs,
  useRefetchExternalDocuments,
} from '../../dispatches/mutations'
import { UploadDocumentForm } from '../UploadDocumentForm'
import { SerializeSlider } from './SerializeSlider'
import { type LoadPanelProps } from './types'

const DeleteBtn = ({ load, doc }: { load: AdminLoad; doc: LoadDocument }) => {
  const deleteDocument = useDeleteLoadDocument()
  const isDeleting =
    deleteDocument.isLoading && deleteDocument.variables?.documentId === doc._id

  return doc.source !== 'system' && !!doc._id ? (
    <AdminButton
      className="btn-xs btn-error"
      loading={isDeleting}
      icon="trash"
      onClick={() => {
        if (
          deleteDocument.isLoading ||
          !window.confirm(
            'Are you sure you want to permanently delete this document?',
          )
        ) {
          return
        }

        deleteDocument
          .mutateAsync({
            documentId: doc._id as string,
            loadId: load._id,
          })
          .catch(e => toast.error(e.message))
      }}
    />
  ) : null
}

export function DocumentsPanel({ load, onChange }: LoadPanelProps) {
  return (
    <AdminSection
      title="Documents"
      headerActions={<DocumentActions load={load} onChange={onChange} />}
    >
      <AdminTable
        data={load.documents}
        rowKey={d => d._id ?? d.url}
        columns={[
          {
            key: 'file',
            label: 'File',
            render: d => (
              <AdminLink href={d.url} target="_blank" rel="noreferrer">
                {d.type}
              </AdminLink>
            ),
          },
          {
            key: 'uploadDate',
            label: 'Date',
            render: d => (
              <AdminDate val={d.uploadDate} format="MM/DD/YYYY HH:mm A" />
            ),
          },
          { key: 'source', label: 'Source', render: d => d.source },
          {
            key: 'del',
            label: '',
            render: d => <DeleteBtn load={load} doc={d} />,
          },
          {
            key: 'user',
            label: 'User',
            render: d => <UserDisplay userId={d.uploadedByUserId} />,
          },
        ]}
      />
    </AdminSection>
  )
}

function DocumentActions({ load, onChange }: LoadPanelProps) {
  const [sliderOpen, setSliderOpen] = useState(false)
  const refetchDocuments = useRefetchExternalDocuments(load._id)
  const canRegenerateBOL =
    !load.isLegacy &&
    (load.pickup?.status === 'ok' || load.pickup?.status === 'pending') &&
    load.howToBook?.createBOL

  return (
    <>
      {!load.isLegacy && (
        <>
          <UploadSlider loadId={load._id} onChange={onChange} />
          {canRegenerateBOL && (
            <>
              <AdminButton
                onClick={() => setSliderOpen(true)}
                icon="redo"
                className="btn-sm"
              >
                Refresh BOL
              </AdminButton>
              <RefreshBOLSlider
                loadId={load._id}
                isOpen={sliderOpen}
                onClose={refresh => {
                  setSliderOpen(false)

                  if (refresh) {
                    onChange?.()
                  }
                }}
              />
            </>
          )}
        </>
      )}

      {load.status === 'booked' && (
        <AdminButton
          icon="cloud-download"
          loading={refetchDocuments.isLoading}
          className="btn-sm"
          onClick={() => refetchDocuments.mutate()}
        >
          Sync w/ carrier
        </AdminButton>
      )}

      <SerializeSlider loadId={load._id} output="documents" />
    </>
  )
}

type SliderProps = {
  loadId: string
  onClose: (refresh: boolean) => void
  isOpen: boolean
}

const RefreshBOLSlider = ({ loadId, onClose, isOpen }: SliderProps) => {
  const [refreshType, setRefreshType] = useState<'fv' | 'carrier'>('fv')
  const generateBOLs = useGenerateBOLs()
  const refreshCarrierBOL = useRefreshCarrierBOL()

  return (
    <AdminSliderPanel
      title="Which BOL would you like to refresh?"
      closePanel={() => onClose(false)}
      isOpen={isOpen}
    >
      <ValidatedForm
        className="grid grid-cols-1 gap-2"
        onValidSubmit={() => {
          refreshType === 'fv'
            ? generateBOLs.mutate([loadId], {
                onSuccess: () => onClose(true),
              })
            : refreshCarrierBOL.mutate(
                { loadId },
                {
                  onSuccess: () => onClose(true),
                },
              )
        }}
      >
        <AdminRadio
          name="fv"
          label="Freightview BOL"
          onChange={() => setRefreshType('fv')}
          checked={refreshType === 'fv'}
        />
        <AdminRadio
          name="carrier"
          label="Carrier BOL"
          onChange={() => setRefreshType('carrier')}
          checked={refreshType === 'carrier'}
        />
        <div className="text-right">
          <AdminButton
            type="submit"
            icon="check"
            loading={generateBOLs.isLoading || refreshCarrierBOL.isLoading}
          >
            Submit
          </AdminButton>
        </div>
      </ValidatedForm>
    </AdminSliderPanel>
  )
}

type UploadSliderProps = {
  loadId: string
  onChange?: () => void
}
const UploadSlider = ({ loadId, onChange }: UploadSliderProps) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <AdminButton
        onClick={() => setOpen(true)}
        icon="upload"
        className="btn-sm"
      >
        Upload
      </AdminButton>
      <AdminSliderPanel
        isOpen={isOpen}
        closePanel={() => setOpen(false)}
        title="Upload document"
      >
        <UploadDocumentForm
          loadId={loadId}
          onSuccess={() => {
            setOpen(false)
            onChange?.()
          }}
        />
      </AdminSliderPanel>
    </>
  )
}

type UserDisplayProps = {
  userId?: string
}

const UserDisplay = ({ userId }: UserDisplayProps) => {
  const user = useFetchGenericUser(userId)

  if (user?.data?.firstName || user?.data?.lastName) {
    return (
      <>
        {user.data?.firstName} {user.data?.lastName}
      </>
    )
  }

  return <>{user.data?.email}</>
}
