import { ObjectId } from '@freightview/object-id'
import { readonly, record } from 'valibot'

import { DTO } from '../core'
import {
  vArray,
  vBoolean,
  vCountryAny,
  vDate,
  vMinValue,
  vNumber,
  vObject,
  vObjectId,
  vOmit,
  vOptional,
  vOutput,
  vPicklist,
  vPipe,
  vPostalCode,
  vString,
  vStringToNumber,
  vTransform,
} from '../validation'
import { ChargeName } from './charge'
import { equipmentAccessorials, equipmentTypes } from './equipment-type'
import { locationAccessorials } from './location-accessorial'
import { isHazardous, ShipperShipment } from './shipment'
import { sortDirSchema } from './sort'
import { states } from './state'

export const contractedRateChargeTypes = [
  'food-grade',
  'tanker-endorsement',
  'chains',
  'e-track',
  'load-bars',
  'liftgate',
  'pipe-stakes',
  'straps',
  'tarps',
  'team-driver',
  'schedule',
  'driver-assist',
  'notify',
  'twic',
  'hazardous',
  'stop-off',
] as const satisfies Partial<
  | (typeof equipmentAccessorials)[number]
  | (typeof locationAccessorials)[number]['key']
  | 'hazardous'
  | 'stop-off'
>[]

export type ContractedChargeType = (typeof contractedRateChargeTypes)[number]

export const contractedRatesFilterSchema = vObject({
  carrierId: vOptional(vString()),
  originSearch: vOptional(vString()),
  destSearch: vOptional(vString()),
  contractSearch: vOptional(vString()),
  showExpired: vOptional(vBoolean()),
  limit: vOptional(vStringToNumber()),
  continuationToken: vOptional(vString()),
  sortBy: vOptional(
    vPicklist([
      'createdAt',
      'startDate',
      'endDate',
      'contractNumber',
      'carrierName',
      'origin',
      'destination',
      'amount',
      'status',
    ]),
  ),
  sortDir: vOptional(sortDirSchema),
})

export type ContractedRatesFilter = vOutput<typeof contractedRatesFilterSchema>

export const extendsRatesSchema = vObject({
  endDate: vDate(),
  rates: vArray(vObjectId()),
})

export type ExtendRatesDTO = vOutput<typeof extendsRatesSchema>

const locationSchema = vObject({
  country: vCountryAny,
  postalCode: vOptional(vPostalCode(true)),
  postalCodeEnd: vOptional(vPostalCode(true)),
  state: vOptional(vPicklist(states.map(s => s.abbreviation))),
  meta: vOptional(
    vPipe(
      vObject({
        city: vOptional(vString()),
        state: vOptional(vPicklist(states.map(s => s.abbreviation))),
      }),
      readonly(),
    ),
  ),
})

const chargeConfig = vObject({
  amount: vOptional(vPipe(vNumber(), vMinValue(1))),
})

export const contractedChargesSchema = vObject({
  carrierId: vPipe(
    vObjectId(),
    vTransform(v => ObjectId.createFromHexString(v)),
  ),
  accountId: vPipe(
    vObjectId(),
    vTransform(v => ObjectId.createFromHexString(v)),
  ),
  charges: record(vPicklist(contractedRateChargeTypes), chargeConfig),
})

export type ContractedCharges = vOutput<typeof contractedChargesSchema>
export type ContractedChargesDTO = DTO<ContractedCharges>

export const contractedRateSchema = vObject({
  _id: vObjectId(),
  carrierName: vOptional(vString()),
  accountId: vString(),
  addedById: vString(),
  carrierId: vString(),
  createdDate: vDate(),
  contractNumber: vOptional(vString()),
  destination: locationSchema,
  distance: vOptional(vPipe(vNumber(), vMinValue(1))),
  distanceUOM: vPicklist(['mi']),
  endDate: vDate(),
  equipmentTypes: vArray(vPicklist(equipmentTypes.map(e => e.key))),
  fuelIncluded: vBoolean(),
  origin: locationSchema,
  rate: vObject({
    amount: vNumber(),
    currency: vPicklist(['usd']),
    rateType: vPicklist(['flat', 'per-mile']),
    serviceId: vPicklist(['truckload']),
  }),
  startDate: vDate(),
  minimumRate: vOptional(vNumber()),
  continuationToken: vOptional(vString()),
  errors: vOptional(
    vArray(
      vObject({
        message: vString(),
        path: vString(),
      }),
    ),
  ),
  isRemoved: vOptional(vBoolean()),
})

export type ContractedRateBase = vOutput<typeof contractedRateSchema>
export type ContractedRateLocation = vOutput<typeof locationSchema>

export type ContractedRate = Partial<DTO<ContractedRateBase>>

export const editContractedRateSchema = vOmit(contractedRateSchema, [
  '_id',
  'carrierName',
  'accountId',
  'addedById',
  'createdDate',
  'continuationToken',
  'errors',
  'isRemoved',
])

export const addRatesSchema = vObject({
  rates: vArray(editContractedRateSchema),
})

export type AddContractedRatesDTO = vOutput<typeof addRatesSchema>

export const displayContractedRateLoc = (loc: ContractedRateLocation) => {
  if (loc.state) {
    return loc.state
  }

  if (loc.postalCode && loc.postalCodeEnd) {
    return `${loc.postalCode}-${loc.postalCodeEnd}`
  }

  if (loc.meta?.city && loc.meta?.state) {
    return `${loc.meta?.city}, ${loc.meta?.state} ${loc.postalCode}`
  }

  return loc.postalCode
}

type ChargeLoad = Pick<ShipperShipment, 'equipment' | 'locations' | 'items'>

export type ContractedChargeConfig = {
  chargeName: ChargeName
  instances: (load: ChargeLoad) => number
  label: string
}

type ContractedChargeMapping = {
  [key in ContractedChargeType]: ContractedChargeConfig
}

export const AccessorialChargeMap: ContractedChargeMapping = {
  'driver-assist': {
    chargeName: 'driver-assist',
    instances: (load: ChargeLoad) =>
      load.locations.filter(l =>
        l.accessorials?.some(a => a.key === 'driver-assist'),
      ).length,
    label: 'Driver assist (per stop)',
  },
  notify: {
    chargeName: 'arrival notice',
    instances: (load: ChargeLoad) =>
      load.locations.filter(l => l.accessorials?.some(a => a.key === 'notify'))
        .length,
    label: 'Arrival notification (per stop)',
  },
  twic: {
    chargeName: 'twic card required',
    instances: (load: ChargeLoad) =>
      load.locations.some(l => l.accessorials?.some(a => a.key === 'twic'))
        ? 1
        : 0,
    label: 'TWIC required',
  },
  hazardous: {
    chargeName: 'hazardous',
    instances: (load: ChargeLoad) => (isHazardous(load) ? 1 : 0),
    label: 'Hazardous',
  },
  'load-bars': {
    chargeName: 'load bars',
    instances: (load: ChargeLoad) =>
      load.equipment.accessorials?.some(a => a.key === 'load-bars') ? 1 : 0,
    label: 'Load bars',
  },
  straps: {
    chargeName: 'straps',
    instances: (load: ChargeLoad) =>
      load.equipment.accessorials?.some(a => a.key === 'straps') ? 1 : 0,
    label: 'Straps',
  },
  'food-grade': {
    chargeName: 'food grade protection',
    instances: (load: ChargeLoad) =>
      load.equipment.accessorials?.some(a => a.key === 'food-grade') ? 1 : 0,
    label: 'Food grade',
  },
  'e-track': {
    chargeName: 'e-track',
    instances: (load: ChargeLoad) =>
      load.equipment.accessorials?.some(a => a.key === 'e-track') ? 1 : 0,
    label: 'E-track',
  },
  'team-driver': {
    chargeName: 'team drivers',
    instances: (load: ChargeLoad) =>
      load.equipment.accessorials?.some(a => a.key === 'team-driver') ? 1 : 0,
    label: 'Team driver',
  },
  liftgate: {
    chargeName: 'liftgate delivery',
    instances: (load: ChargeLoad) =>
      load.equipment.accessorials?.some(a => a.key === 'liftgate') ? 1 : 0,
    label: 'Liftgate',
  },
  'tanker-endorsement': {
    chargeName: 'tanker endorsement',
    instances: (load: ChargeLoad) =>
      load.equipment.accessorials?.some(a => a.key === 'tanker-endorsement')
        ? 1
        : 0,
    label: 'Tanker endorsement',
  },
  tarps: {
    chargeName: 'tarp',
    instances: (load: ChargeLoad) =>
      load.equipment.accessorials?.some(a => a.key === 'tarps') ? 1 : 0,
    label: 'Tarps',
  },
  chains: {
    chargeName: 'chains',
    instances: (load: ChargeLoad) =>
      load.equipment.accessorials?.some(a => a.key === 'chains') ? 1 : 0,
    label: 'Chains',
  },
  'pipe-stakes': {
    chargeName: 'pipe stakes',
    instances: (load: ChargeLoad) =>
      load.equipment.accessorials?.some(a => a.key === 'pipe-stakes') ? 1 : 0,
    label: 'Pipe stakes',
  },
  schedule: {
    chargeName: 'schedule',
    instances: (load: ChargeLoad) =>
      load.locations.filter(l =>
        l.accessorials?.some(a => a.key === 'schedule'),
      ).length,
    label: 'Appointment required (per stop)',
  },
  'stop-off': {
    chargeName: 'stop-off',
    instances: (load: ChargeLoad) => load.locations.length - 2,
    label: 'Stop-off (per stop)',
  },
} as const
