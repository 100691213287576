import clsx from 'clsx'

import { Tooltip, type TooltipProps } from '@fv/client-components'

type Props = TooltipProps
export const AdminTooltip = (props: Props) => {
  return (
    <Tooltip
      {...props}
      className="border bg-neutral border-primary text-neutral-content p-3"
      arrowClassName={context =>
        clsx('border-primary bg-neutral border ', {
          'border-t-0 border-l-0': context.placement === 'top',
          'border-b-0 border-r-0': context.placement === 'bottom',
        })
      }
    >
      {props.children}
    </Tooltip>
  )
}
